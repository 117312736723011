import React from 'react';

const TodoItem = ({ todo, toggleTodo, deleteTodo }) => {
  const handleToggle = () => {
    toggleTodo(todo.id);
  };

  const handleDelete = () => {
    deleteTodo(todo.id);
  };



  // Ensure that todo is not undefined before accessing its properties
  const inputId = todo ? `todo-${todo.id}` : ''; // Generate unique ID if todo exists

  return (
    <tr>
        <td>{todo.id}</td>
      <td width={10}>
        <input id={inputId} type="checkbox" checked={todo.completed} onChange={handleToggle}/>
      </td>
      <td style={{textAlign:'left', padding:'10px', width:'100%' }}>
        <label htmlFor={inputId} className={'textDecoration'} style={{ color: todo.completed ? '#ccc':'#555', textDecoration: todo.completed ? 'line-through' : 'none' }}>
          {todo.text}
        </label>
      </td>
      <td>
        <button onClick={handleDelete}>Remove</button>
      </td>
    </tr>
  );
};

export default TodoItem;
