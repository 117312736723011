// App.js
import React from 'react';
import './App.css';
import TodoList from './TodoList'; // Import the TodoList component


function App() {
  return (
    <div className="App">
      <TodoList /> {/* Render the TodoList component */}
      
      <div className='footer'><i>Project 1</i></div>
    </div>
    
  );
}

export default App;
